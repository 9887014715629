<template>
  <div>
    帮助  ヘルプ
  </div>
</template>

<script>
export default {
  name: "help"
}
</script>

<style scoped>

</style>
